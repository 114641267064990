:root {
  --1: #61649d;
  --2: #cadfff;
  --3: #859dc2;
  --4: #adcdfd;
  --5: #7a91b3;
  --6: #273543;
  --7: #f4679b;
  --8: #f5f8fa;
  --9: #72a5df;
  --10: #b89301;
  --11: #406084;
  --12: #e2e4ff;
  --13: #7274a3;
  --14: #506684;
  --15: #adcdfd92;
  --16: #4e6384de;
  --17: rgb(128, 215, 36);
  --18: #36577c;
  --19: #f5f8fa;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar {
  position: relative;
  background: var(--19);
}
::-webkit-scrollbar-button {
  background: var(--19);
  height: 5rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--7);
  width: 0.5rem;
  border-radius: 0.3rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}
body {
  background: var(--19);
  user-select: none;
  -webkit-user-select: none;
  margin: 0;
}
section {
  height: 3.125rem;
  width: 100%;
  display: flex;
  place-content: center space-evenly;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-content: center;
  padding: 0 2rem 0 2rem;
}
nav.main-header-menu {
  display: flex;
  justify-content: space-evenly;
  align-content: stretch;
  align-items: center;
  width: 100%;
  position: fixed;
  background: var(--19);
  height: 5rem;
  z-index: 10;
  transition: all 0.2s ease-in-out;
}
a.nav-link {
  display: flex;
  justify-content: center;
  margin: 0 0.5rem 0 0.5rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  border-radius: 0.5rem;
}
a.nav-link:hover {
  box-shadow: var(--7) 0.2rem 0.2rem, var(--8) 0.4rem 0.4rem,
    var(--13) 0.6rem 0.6rem;
  border-radius: 0.5rem;
}
nav a {
  font-size: 0.938rem;
  text-transform: uppercase;
  color: var(--1);
  text-decoration: none;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 3.125rem;
  z-index: 1;
  text-align: center;
}
nav a:nth-child(1) {
  width: 6rem;
  transition: all 0.2s ease-in-out;
}
nav a:nth-child(2) {
  width: 6rem;
  transition: all 0.2s ease-in-out;
}
nav a:nth-child(3) {
  width: 6rem;
  transition: all 0.2s ease-in-out;
}
nav a:nth-child(4) {
  width: 6rem;
  transition: all 0.2s ease-in-out;
}
a.nav-link.active {
  border-radius: 0 0 0.5rem 0.5rem;
  color: var(--1);
  background: var(--19);
  box-shadow: 0.3rem 0.3rem 0 0 var(--7);
  border-radius: 0.5rem;
}
a.nav-link.active:hover {
  box-shadow: var(--7) 0.2rem 0.2rem, var(--8) 0.4rem 0.4rem,
    var(--7) 0.6rem 0.6rem;
  border-radius: 0.5rem;
}
div.C1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--1);
  font-size: large;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 6rem 0;
}
div.C12ndChild {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--1);
  font-size: large;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
/* cards contained in C2(Projects) */
div.cardsp {
  position: relative;
  top: 1rem;
  height: 25rem;
  width: 20rem;
  font-size: large;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  background: var(--19);
  border-radius: 0.5rem;
  margin: 0.6rem;
  display: inline-block;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  transition: all 0.2s ease-in-out;
}
div.cardsp:hover {
  position: relative;
  top: 1rem;
  height: 25rem;
  width: 20rem;
  font-size: large;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  background: var(--19);
  border-radius: 0.5rem;
  margin: 0.6rem;
  display: inline-block;
  box-shadow: var(--7) 0.2rem 0.2rem, var(--8) 0.4rem 0.4rem,
    var(--13) 0.6rem 0.6rem;
}
div.C11stChild {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
}
title.titlesphome {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  top: 0.5rem;
  width: 100%;
  height: 5rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  text-transform: uppercase;
  font-weight: 900;
  line-height: 0.8;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4vh;
  margin: 0 1rem 0 1rem;
}
div.sphome {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  right: 0.6rem;
  width: 20rem;
  height: 5rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  text-transform: uppercase;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
div.cardsp div.spImg1Home img {
  position: relative;
  left: 3.4rem;
  align-items: center;
  justify-content: center;
  height: 12rem;
  width: 12rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
div.spImg2Home img {
  position: relative;
  top: 4.8rem;
  right: 0.6rem;
  align-items: center;
  justify-content: center;
  height: 12rem;
  width: 20rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
div.spImg3Home img {
  position: relative;
  align-items: center;
  justify-content: center;
  left: 1.4rem;
  height: 12rem;
  width: 16rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
div.spImg4Home img {
  position: relative;
  top: 6.6rem;
  right: 3.15rem;
  align-items: center;
  justify-content: center;
  height: 18.95rem;
  width: 14.9rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
a.buttonsp {
  position: inherit;
  align-items: center;
  justify-content: center;
  background-color: var(--19);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 12.8rem;
  width: 20rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
h1.homewordbubble {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  top: 1.5rem;
  right: 0.6rem;
  width: 20rem;
  height: 17rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 750;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  word-wrap: break-word;
}
h2.homewordbubble {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  top: 4.62rem;
  right: 0.6rem;
  width: 20rem;
  height: 12rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 750;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  word-wrap: break-word;
}
div.HB {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 20rem;
}
div.HB h3 {
  font-size: medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
div.HB h4 {
  font-size: medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
h3.homewordbubble {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  bottom: 1rem;
  right: 0.6rem;
  width: 20rem;
  height: 5rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 750;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
}
div.C2 {
  display: flex;
  flex-wrap: wrap;
  color: var(--1);
  font-size: large;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  padding: 4.9rem 0;
}
/* cards contained in C2(Projects) */
div.card {
  height: 30rem;
  width: 20rem;
  font-size: large;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  background: var(--19);
  border-radius: 0.5rem;
  margin: 0.6rem;
  display: inline-block;
  box-shadow: var(--13) 0.3rem 0.3rem;
  transition: all 0.2s ease-in-out;
}
div.card:hover {
  height: 30rem;
  width: 20rem;
  font-size: large;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  background: var(--19);
  border-radius: 0.5rem;
  margin: 0.6rem;
  display: inline-block;
  box-shadow: var(--7) 0.2rem 0.2rem, var(--8) 0.4rem 0.4rem,
    var(--13) 0.6rem 0.6rem;
}

/* Button Parent */
div.BP {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  width: 20rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
div.titlegcb {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  right: 0.6rem;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  text-transform: uppercase;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
div.gcb {
  position: relative;
  top: 1rem;
  right: 0.6rem;
}
div.gcb img {
  align-items: center;
  justify-content: center;
  height: 11.5rem;
  width: 20rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
a.buttongcb {
  position: inherit;
  align-items: center;
  justify-content: center;
  background-color: var(--19);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 12.8rem;
  width: 20rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
div.titlekyt {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  right: 0.6rem;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  text-transform: uppercase;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
div.kyt {
  position: relative;
  top: 1rem;
  right: 0.6rem;
}
div.kyt img {
  align-items: center;
  justify-content: center;
  height: 11.5rem;
  width: 20rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
a.buttonkyt {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--1);
  text-decoration: none;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10rem;
  transition: all 0.2s ease-in-out;
}
div.titleEp {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  right: 0.6rem;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  text-transform: uppercase;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
div.ep {
  position: relative;
  top: 1rem;
  right: 0.6rem;
}
div.ep img {
  align-items: center;
  justify-content: center;
  height: 11.5rem;
  width: 20rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
a.buttonep {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--1);
  text-decoration: none;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10rem;
  transition: all 0.2s ease-in-out;
}
div.titlewm {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  right: 0.6rem;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  text-transform: uppercase;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
div.wm {
  position: relative;
  top: 1rem;
  right: 0.6rem;
}
div.wm img {
  height: 11.5rem;
  width: 20rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
a.buttonwm {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--1);
  text-decoration: none;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10rem;
  transition: all 0.2s ease-in-out;
}
div.titlesp {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  right: 0.6rem;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  text-transform: uppercase;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}
div.sp {
  position: relative;
  top: 1rem;
  right: 0.6rem;
}
div.sp img {
  height: 11.5rem;
  width: 20rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
a.buttonsp {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--1);
  text-decoration: none;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10rem;
  transition: all 0.2s ease-in-out;
}
h1.wordbubble {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1.5rem;
  right: 0.6rem;
  width: 20rem;
  height: 11.5rem;
  border-radius: 0.5rem;
  background: var(--19);
  color: var(--1);
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
  font-weight: 750;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
div.h23 {
  display: flex;
  width: 20rem;
  height: 6rem;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
h2 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  font-weight: 750;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
h3 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  font-weight: 750;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
div.C3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 5.5rem 0;
}
div.set {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0rem 0rem;
  justify-content: center;
}
button {
  transition: all 0.2s ease-in-out;
}
button:hover {
  box-shadow: var(--7) 0.2rem 0.2rem, var(--8) 0.4rem 0.4rem,
    var(--13) 0.6rem 0.6rem;
}
.BD {
  background-image: url(./components/Images/BD.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.MM {
  background-image: url(./components/Images/MM.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.FM {
  background-image: url(./components/Images/FM.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.SL {
  background-image: url(./components/Images/SL.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.SW {
  background-image: url(./components/Images/SW.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.AS {
  background-image: url(./components/Images/AS.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.FD {
  background-image: url(./components/Images/FD.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.FR {
  background-image: url(./components/Images/FR.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.HZ {
  background-image: url(./components/Images/HZ.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.RS {
  background-image: url(./components/Images/RS.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.SH {
  background-image: url(./components/Images/SH.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.SU {
  background-image: url(./components/Images/SU.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.BF {
  background-image: url(./components/Images/BF.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.CH {
  background-image: url(./components/Images/CH.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.GR {
  background-image: url(./components/Images/GR.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.AG {
  background-image: url(./components/Images/AG.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.SN {
  background-image: url(./components/Images/SN.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
.WW {
  background-image: url(./components/Images/WW.jpg);
  align-items: center;
  justify-content: center;
  background-color: #2e2721;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0 0 var(--13);
}
footer {
  position: fixed;
  bottom: 0;
  height: 5rem;
  width: 100%;
  display: inline-block;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-direction: row;
  align-items: center;
  background: var(--19);
  z-index: 10;
}
div.ft {
  width: 1%;
}
img.linkedln {
  height: 3rem;
  width: 37%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
}
img.instagram {
  height: 3rem;
  width: 37%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
}
img.github {
  height: 3rem;
  width: 37%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
}
